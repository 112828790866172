//const baseUrl = "http://res.api.53online.cn/api/"; //正式环境
//const baseUrl1 = "http://210.13.51.100:8031/api/"; //测试环境

const baseUrl = `${process.env.VUE_APP_BASE_URL}api/`;

export default {
  //获取资源信息
  modelUrl: baseUrl + "Resource/GetModel",

  //获取跟读列表
  listUrl: baseUrl + "FollowRead/GetFollowList",

  //1上传录音文件
  uploadFileUrl: baseUrl + "FollowRead/UploadFiles",

  //2评测打分
  oraUrl: baseUrl + "FollowRead/OralEvalute",

  //3保存用户得分
  saveUserUrl: baseUrl + "FollowRead/SaveUserScore",

  //获取openid
  getOpenidUrl: baseUrl + "Wechat/GetOpenId",

  //上一个
  getPreUrl: baseUrl + "Resource/Pre",

  //下一个
  getNextUrl: baseUrl + "Resource/Next",

  //首页-目录列表
  getListUrl: baseUrl + "Catalog/GetList",

  //资源列表
  getResListUrl: baseUrl + "Resource/GetList",

  //发送邮箱
  mailUrl: baseUrl + "Catalog/SendMail",
};
