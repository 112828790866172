import Qs from "qs";

export default {
  //转url
  urlEncode(str) {
    str = (str + "").toString();
    return encodeURIComponent(str)
      .replace(/!/g, "%21")
      .replace(/'/g, "%27")
      .replace(/\(/g, "%28")
      .replace(/\)/g, "%29")
      .replace(/\*/g, "%2A")
      .replace(/%20/g, "+");
  },
  //获取随机数
  getRandom(min, max) {
    return Math.floor(Math.random() * (max - min) + min);
  },

  //获取当前裸URL
  getUrlClean(url) {
    let host = url.split("?")[0];
    return host;
  },
  getUrlClean1() {
    return document.location.protocol + "//" + window.location.hostname;
  },
  //获取URL参数 土方法
  getUrlParams(url) {
    let obj = {};
    let paramsArr = url.split("?")[1].split("&");
    for (let i = 0, len = paramsArr.length; i < len; i++) {
      let arr = paramsArr[i].split("=");
      obj[arr[0]] = arr[1];
    }
    return obj;
  },
  //获取URL参数 洋方法
  getUrlParams1(url) {
    return Object.fromEntries(new URLSearchParams(url.split("?")[1]).entries());
  },
  //获取URL参数 qs简介方法
  getUrlParams2(url) {
    return Qs.parse(url.split("?")[1]);
    // let otherParams = {
    //   num: 50,
    //   size: 6.1,
    // };
    // let str = Qs.stringify(otherParams);
    // let newUrl = url + str;
    // return { result, newUrl };
  },
};
