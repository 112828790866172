/**
 * @author mxy
 * @description 保存cookie
 * @param {String} name 需要存储cookie的key
 * @param {String} value 需要存储cookie的value
 * @param {Number} timer 默认存储多少天
 */
function setCookie(name, value, timer = 7) {
  let Days = timer; //默认将被保存  天
  let exp = new Date();
  exp.setTime(exp.getTime() + Days * 24 * 60 * 60 * 1000);
  document.cookie =
    name + "=" + escape(value) + ";expires=" + exp.toGMTString();
}

/**
 * @author mxy
 * @description 获取cookie
 * @param {String} name 需要获取cookie的key
 */
function getCookie(name) {
  let arr = document.cookie.match(new RegExp("(^| )" + name + "=([^;]*)(;|$)"));
  if (arr != null) {
    return unescape(arr[2]);
  } else {
    return null;
  }
}

/**
 * @author mxy
 * @description 删除cookie
 * @param {String} name 需要删除cookie的key
 */
function clearCookie(name) {
  let exp = new Date();
  exp.setTime(exp.getTime() - 1);
  let cval = getCookie(name);
  if (cval != null)
    document.cookie = name + "=" + cval + ";expires=" + exp.toGMTString();
}

function timeFormat(e) {
  let d = new Date(e);
  let yyyy = d.getFullYear();
  let MM = (d.getMonth() + 1).toString().padStart(2, "0");
  let dd = d.getDate().toString().padStart(2, "0");
  let h = d.getHours().toString().padStart(2, "0");
  let m = d.getMinutes().toString().padStart(2, "0");
  let s = d.getSeconds().toString().padStart(2, "0");
  return yyyy + "-" + MM + "-" + dd + " " + h + ":" + m + ":" + s;
}

export default {
  setCookie,
  getCookie,
  clearCookie,
  timeFormat,
};
